import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Seo, PageWidth } from "react-wp-gql";
import { LeadForm, LeadFormGroup, Valid } from "react-wp-form";
import { isWebpSupported } from "../utils/Browser";
import {
  Hero,
  Introduction,
  Accountable,
  WhyUse,
  Situation,
  Services,
  RecentJobs,
} from "../home";

const HOME_QUERY = gql`
  query HomeQuery {
    frontPage {
      id
      title
      content
      seo {
        title
        metaDesc
      }
    }
  }
`;

const OnQueryFinished = ({ seo }) => (
  <div className="home">
    <Seo {...seo} />
    <Hero />

    <Introduction id="welcome" className="pv5 bg-dark-gray moon-gray" />
    <Accountable
      className="pv5 bg-dark-gray white bg-center cover"
      style={{
        backgroundImage: isWebpSupported()
          ? `url("${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/accountable.webp")`
          : `url("${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/accountable.jpg")`,
        backgroundAttachment: "fixed",
      }}
    />
    <WhyUse id="about" className="bg-near-white" />
    <Situation
      className="pv5 bg-center cover"
      style={{
        backgroundImage: isWebpSupported()
          ? `url("${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/accountable.webp")`
          : `url("${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/accountable.jpg")`,
        backgroundAttachment: "fixed",
      }}
    />
    <Services id="services" className="tc bg-light-gray pv5" />
    <RecentJobs id="work" className="tc bg-white pt5" />

    <div className="bg-orange white pv5" id="contact">
      <PageWidth>
        <div className="f7 ttu mb1">
          Fill out the form and we'll be in touch soon!
        </div>
        <div className="f2 ttu mb4">Ready to Request a Quote?</div>
        <LeadForm buttonLabel="SEND MESSAGE">
          <LeadFormGroup
            id="yourName"
            placeholder="Your Name"
            valid={Valid.NotEmptyString}
            error="You must include a name."
          />
          <LeadFormGroup
            id="email"
            placeholder="Your Email"
            type="email"
            valid={Valid.Email}
            error="You must include a email."
            className={`w-50-l fl-l pr2-l`}
          />
          <LeadFormGroup
            id="phone"
            placeholder="Your Phone"
            type="tel"
            className={`w-50-l fl-l pl2-l`}
          />
          <LeadFormGroup id="message" placeholder="Message" type="textarea" />
        </LeadForm>
      </PageWidth>
    </div>
  </div>
);

export const Home = () => {
  const { error, data } = useQuery(HOME_QUERY, { errorPolicy: "all" });

  return (
    <OnQueryFinished
      seo={data?.frontPage?.seo}
      content={data?.frontPage?.content}
      error={error?.message}
    />
  );
};

export default Home;
