import React, { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Permissions, LoadingError } from "react-wp-gql";
import { useParams } from "react-router-dom";
import Button from "../components/Button";
import UserRow, { Row, UserAdd } from "../dashboard/UserRow";
import VideoRows from "../dashboard/VideoRows";
import Upload from "../dashboard/Upload";
import FileRows from "../dashboard/FileRows";

const QUERY = gql`
  query GetProject($name: ID!) {
    wpBoilerplateNodesSettings: themeMods {
      googleMaps: mapApi
    }
    project(id: $name, idType: URI) {
      id
      title
      databaseId
      authorDatabaseId
      slug
      users {
        nodes {
          id
          databaseId
          name
          email
          lastSeen
        }
      }
      schedule {
        projectFieldset1Start
        projectFieldset1ScheduledEnd
      }
      featuredImage {
        node {
          sourceUrl(size: LARGE)
        }
      }
    }
  }
`;

const GOT_IMAGE = gql`
  mutation ChangeImage(
    $projectDatabaseId: ID!
    $imageDatabaseId: ID!
    $clientMutationId: String!
  ) {
    changeProjectThumbnail(
      input: {
        clientMutationId: $clientMutationId
        projectDatabaseId: $projectDatabaseId
        thumbnail: $imageDatabaseId
      }
    ) {
      clientMutationId
      mediaItem {
        sourceUrl(size: LARGE)
      }
    }
  }
`;

export const ProjectView = () => {
  const [uploadErrorMsg, setUploadErrorMsg] = useState();
  const [replaceThumbnailActive, setReplaceThumbnailActive] = useState(false);
  const [addingUser, toggleAddUser] = useState(false);
  const [image, setImage] = useState();

  const [runMutation, { data: GotImageData }] = useMutation(GOT_IMAGE);

  const { name } = useParams();

  const { error, data = {} } = useQuery(QUERY, {
    variables: { name: `/?projects=${name}` },
  });

  const { databaseId } = data.project || {};

  useEffect(() => {
    if (data) {
      const i = data?.project?.featuredImage?.node?.sourceUrl;
      setImage(i);
    }

    if (GotImageData) {
      const src = GotImageData?.changeProjectThumbnail?.mediaItem?.sourceUrl;
      setImage(src);
    }
  }, [data, GotImageData]);

  if (error) return <LoadingError error={error.message} />;

  return (
    <div>
      <h2 className="mt0">Project: {data?.project?.title}</h2>

      <div className="flex-l nl2-l nr2-l">
        {data?.project?.schedule?.projectFieldset1Start && (
          <div className="ma2 w-50-l">
            Start Date: {data.project.schedule.projectFieldset1Start}
          </div>
        )}
        {data?.project?.schedule?.projectFieldset1ScheduledEnd && (
          <div className="ma2 w-50-l">
            Scheduled End Date:{" "}
            {data.project.schedule.projectFieldset1ScheduledEnd}
          </div>
        )}
      </div>

      {uploadErrorMsg && (
        <div className="red pa3 br2 b--red ba b1 mb3">{uploadErrorMsg}</div>
      )}

      {replaceThumbnailActive && (
        <Upload
          accept="image/*"
          onFailure={(file) => {
            console.error(file);
            setUploadErrorMsg(file.message || "Unable to upload.");
          }}
          onComplete={(file) => {
            if (file.response) {
              runMutation({
                variables: {
                  clientMutationId: new Date().getTime().toString(36),
                  imageDatabaseId: file.response,
                  projectDatabaseId: databaseId,
                },
              });
            }

            setReplaceThumbnailActive(false);
          }}
        />
      )}

      <div className="flex-l nl2-l nr2-l">
        <div className="ma2 w-50-l bg-gray hide-child relative z-1">
          <Permissions
            cap="edit_projects"
            authorId={data?.project?.authorDatabaseId}
          >
            <div
              className="child bg-black-50 absolute z-2 absolute--fill"
              onClick={() => setReplaceThumbnailActive(!replaceThumbnailActive)}
            >
              <div className="white f4 h-100 flex items-center pointer">
                <div className="center">Click to replace</div>
              </div>
            </div>
          </Permissions>
          {image && (
            <div className="aspect-ratio aspect-ratio--16x9 z-1">
              <div
                className="bg-center cover aspect-ratio--object"
                style={{
                  backgroundImage: `url("${image}")`,
                }}
              />
            </div>
          )}
        </div>

        <div className="ma2 w-50-l bg-gray">
          {data?.project?.title && (
            <div className="aspect-ratio aspect-ratio--16x9">
              <iframe
                title={data.project.tile}
                className="aspect-ratio--object"
                src={`https://www.google.com/maps/embed/v1/place?key=${
                  data?.wpBoilerplateNodesSettings?.googleMaps
                }&q=${encodeURI(data.project.title)}`}
                allowFullScreen
              />
            </div>
          )}
        </div>
      </div>

      <Permissions
        cap="edit_projects"
        authorId={data?.project?.authorDatabaseId}
      >
        <div className="mv4 pa3 bg-near-white">
          <h3 className="mv0">Users</h3>

          <div className="dt-l w-100-l nl2-l nr2-l">
            {(data?.project?.users?.nodes?.length > 0 || addingUser) && (
              <Row
                border={false}
                left="Email"
                middle="Password"
                right="Last Login"
                className="dn"
              />
            )}

            {data?.project?.users?.nodes?.length > 0 &&
              data.project.users.nodes.map((node) => (
                <UserRow
                  projectId={data.project.databaseId}
                  key={node.id}
                  user={node}
                  disabled={addingUser}
                />
              ))}

            {addingUser && (
              <UserAdd
                projectId={data.project.databaseId}
                onFinish={() => toggleAddUser(false)}
              />
            )}
          </div>

          <div className="tr mt3 f6">
            <Button onClick={() => toggleAddUser((prev) => !prev)}>
              {addingUser ? "Cancel" : "Add User"}
            </Button>
          </div>
        </div>
      </Permissions>

      <div className="mv4">
        <h3 className="mv0">Videos</h3>

        {data?.project?.databaseId && (
          <VideoRows
            projectId={data.project.databaseId}
            authorId={data.project.authorDatabaseId}
          />
        )}
      </div>

      <div className="mv4">
        <h3 className="mv0">Documents</h3>

        {data?.project?.databaseId && (
          <FileRows
            projectId={data.project.databaseId}
            authorId={data.project.authorDatabaseId}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectView;
