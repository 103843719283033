import React, { useRef, useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";

import Image from "../components/Image";
import {
  PageWidth,
  scrollHandler,
  Menu,
  MenuItem,
  MenuItemAnchor,
  Permissions,
} from "react-wp-gql";
import { isMobile } from "../utils/Browser";

const openMenu = () => {
  let menu = document.getElementById("menu-header-menu").classList;
  if (menu.contains("dn")) {
    menu.remove("dn");
  } else {
    menu.add("dn");
  }
};

const HeaderBody = ({ sticky }) => {
  const backgroundColor = useRef();
  const logoOrange = useRef();
  const logoBlue = useRef();
  const nav = useRef();

  useEffect(() => {
    const bg = backgroundColor?.current;
    const oCL = logoOrange?.current?.classList;
    const bCL = logoBlue?.current?.classList;
    const nCL = nav?.current?.classList;

    const onScroll = ({ y }) => {
      if (bg) {
        if (y > 150) {
          bg.classList.add("bg-white");
          bCL.add("db");
          bCL.remove("dn");

          oCL.add("dn");
          oCL.remove("db");

          nCL.add("gray");
          nCL.remove("white");
        } else {
          bg.classList.remove("bg-white");
          oCL.add("db");
          oCL.remove("dn");

          bCL.add("dn");
          bCL.remove("db");

          nCL.add("white");
          nCL.remove("gray");
        }
      }
    };

    if (bg) {
      scrollHandler.add(onScroll, false);
    }

    return () => scrollHandler.remove(onScroll);
  }, [backgroundColor, logoBlue, logoOrange, nav]);

  return (
    <header id="header">
      <div
        className={`w-100 z-2 ${
          sticky && !isMobile() ? "absolute fixed-l top-0" : "relative"
        }`}
      >
        <div ref={backgroundColor} className="bg-animate">
          <nav>
            <PageWidth className="dt-l">
              <div className="brand flex items-center tc dtc-l v-mid-l tl-l">
                <div
                  className="mobile-toggle pr3 pv3 db dn-l pointer"
                  onClick={openMenu}
                >
                  {Array.from(new Array(3)).map(() => (
                    <div
                      key={Math.random()}
                      className="w2 bg-navy pb1 mt1 mb1"
                    />
                  ))}
                </div>
                <Link
                  to="/"
                  className="dib border-box mv3"
                  onClick={() =>
                    document
                      .getElementById("menu-header-menu")
                      .classList.add("dn")
                  }
                >
                  <Image
                    src={`${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/logo-orange.png`}
                    webp={`${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/logo-orange.webp`}
                    className="db"
                    height="50"
                    ref={logoOrange}
                  />
                  <Image
                    src={`${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/logo-blue.png`}
                    webp={`${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/logo-blue.webp`}
                    className="dn"
                    height="40"
                    ref={logoBlue}
                  />
                </Link>
              </div>
              <div className="db tc dtc-l v-mid-l tr-l">
                <Menu
                  ref={nav}
                  location="HEADER_MENU"
                  className={`dn ma0 pl0 list db-l ${
                    isMobile() || !sticky ? "navy" : "white"
                  }`}
                  onClick={openMenu}
                >
                  <MenuItem>
                    <Permissions
                      cap="read_documents"
                      fallback={SignInLink}
                      wait
                    >
                      <MenuItemAnchor to="/dashboard">Dashboard</MenuItemAnchor>
                    </Permissions>
                  </MenuItem>
                </Menu>
              </div>
            </PageWidth>
          </nav>
        </div>
      </div>
    </header>
  );
};

const SignInLink = () => <MenuItemAnchor to="/login">Sign In</MenuItemAnchor>;

export const Header = () => (
  <Switch>
    <Route
      path="/"
      exact
      render={(props) => <HeaderBody {...props} sticky={true} />}
    />
    <Route render={(props) => <HeaderBody {...props} sticky={false} />} />
  </Switch>
);

export default Header;
