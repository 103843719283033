import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { FormGroup as Input } from "react-wp-form";
import { Loading, LoadingError } from "react-wp-gql";
import Button from "../components/Button";

const QUERY = gql`
  query ProfileQuery {
    viewer {
      id
      name
      email
      registeredDate
      lastSeen
      roles {
        nodes {
          name
        }
      }
      projects(first: 4) {
        nodes {
          id
          databaseId
          slug
          featuredImage {
            node {
              sourceUrl(size: LARGE)
            }
          }
          title
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation ProfileMutation(
    $name: String!
    $email: String!
    $id: ID!
    $clientMutationId: String!
  ) {
    updateUser(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        email: $email
        displayName: $name
      }
    ) {
      user {
        id
        email
        name
        databaseId
      }
    }
  }
`;

const Stat = ({ label, value, to, ...props }) => (
  <li {...props}>
    <div className="bg-near-white ba b--moon-gray pa2 dib moon-gray f7 ma2 relative z-1">
      <div>{label}</div>
      <div className="fw7 gray">{value}</div>
      {to && <Link to={to} className="absolute absolute--fill db z-2" />}
    </div>
  </li>
);

export const Profile = () => {
  const { error, data } = useQuery(QUERY);
  const [viewer, setViewer] = useState({ name: "", email: "" });

  useEffect(() => {
    const { name = "", email = "" } = data?.viewer || {};
    setViewer({ name, email });
  }, [data]);

  const onCompleted = (data) => {
    const { name = "", email = "" } = data?.updateUser?.user || {};
    setViewer({ name, email });
  };

  const [runMutation, { loading }] = useMutation(MUTATION, { onCompleted });

  const submit = () => {
    runMutation({
      variables: {
        ...viewer,
        id: data.viewer.id,
        clientMutationId: data.viewer.id + new Date().getTime().toString(36),
      },
    });
  };

  return (
    <div className="profile">
      <h2 className="mt0">Profile</h2>
      {error && <LoadingError error={error.message} />}

      {data?.viewer && (
        <div className="cf">
          <Input
            id="profile-name"
            label="Name"
            value={viewer.name}
            onChange={(name) => setViewer({ ...viewer, name })}
            onEnter={submit}
          />
          <Input
            id="profile-email"
            label="Email"
            value={viewer.email}
            type="email"
            onChange={(email) => setViewer({ ...viewer, email })}
            onEnter={submit}
          />
          <div className="fr mt3 flex items-center">
            <Button form={true} onClick={submit}>
              <span>Update</span>
              {loading && (
                <Loading color="#FFF" className="f7 ml2 nt2 nb2 nr3" />
              )}
            </Button>
          </div>
        </div>
      )}

      <h2 className="mt0">Stats</h2>
      <ul className="list pl0 flex-l flex-wrap-l nl2-l nt2-l">
        {data?.viewer?.registeredDate && (
          <Stat
            label="Join Date"
            value={moment.utc(data.viewer.registeredDate).format("MMMM DD, Y")}
          />
        )}

        {data?.viewer?.lastSeen && (
          <Stat
            label="Last Active"
            value={moment.utc(data.viewer.lastSeen).fromNow()}
          />
        )}

        {data?.viewer?.roles?.nodes?.length > 0 && (
          <Stat
            label="Roles"
            value={data.viewer.roles.nodes
              .map(
                (node) => node.name.charAt(0).toUpperCase() + node.name.slice(1)
              )
              .join(", ")}
          />
        )}
      </ul>

      <h2 className="mt0">Recent Projects</h2>
      <ul className="list pl0 flex-l flex-wrap-l nl2-l nt2-l">
        {data?.viewer?.projects?.nodes?.length > 0 &&
          data.viewer.projects.nodes.map((node) => (
            <Stat
              key={node.id}
              className="w-100 w-20-l pointer"
              value={node.title}
              to={`/dashboard/projects/${node.slug}`}
              label={
                <div
                  style={{
                    backgroundImage: `url("${node.featuredImage?.node?.sourceUrl}")`,
                  }}
                  className="bg-center cover aspect-ratio--4x3 aspect-ratio mb2"
                />
              }
            />
          ))}
      </ul>
    </div>
  );
};

export default Profile;
