// React
import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { WordPressRoutes, Node } from "react-wp-gql";
import { Header, Footer, Main, Dashboard, Home } from "./layout";
import LightBox from "./components/LightBox";
import "animate.css";
import "./app.scss";
import { isWebpSupported } from "./utils/Browser";

export const App = () => {
  const { search } = useLocation();
  const previewId = new URLSearchParams(search).get("p");

  useEffect(() => {
    const root = document.getElementById("root");

    if (isWebpSupported()) {
      root.classList.add("webp");
    }

    // Prevents the jpg from loading before the webp class is added.
    // Image background is added on loaded class.
    root.classList.add("loaded");
  }, []);

  return (
    <div className="min-vh-100 flex items-stretch flex-column w-100 sans-serif near-black">
      <Header />
      <Main>
        <Switch>
          <Route exact path="/">
            {previewId ? <Node databaseId={previewId} /> : <Home />}
          </Route>

          <Route path="/dashboard">
            <Dashboard />
          </Route>

          <WordPressRoutes />
        </Switch>
      </Main>
      <Footer />

      <LightBox />
    </div>
  );
};
