import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import moment from "moment";

import Button from "../components/Button";
import { FormGroup as Input, Valid } from "react-wp-form";

const UPDATE_MUTATION = gql`
  mutation addUserToProjectMutation(
    $clientMutationId: String!
    $id: ID!
    $userId: ID
    $email: String!
    $password: String
  ) {
    addUserToProjectMutation(
      input: {
        clientMutationId: $clientMutationId
        id: $id
        userId: $userId
        email: $email
        password: $password
      }
    ) {
      errorMessage
      project {
        id
        users {
          nodes {
            id
            databaseId
            name
            email
            lastSeen
          }
        }
      }
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation deleteUserFromProjectMutation(
    $clientMutationId: String!
    $id: ID!
    $userId: ID!
  ) {
    deleteUserFromProjectMutation(
      input: { clientMutationId: $clientMutationId, id: $id, userId: $userId }
    ) {
      errorMessage
      project {
        id
        users {
          nodes {
            id
            databaseId
            name
            email
            lastSeen
          }
        }
      }
    }
  }
`;

const Label = ({ children }) => (
  <label className="dn-l db mb1">{children}</label>
);

const Cell = ({ children, className = "", border = true }) => (
  <div
    className={`dtc-l mb0-l mb3 pa2-l pa0 v-mid ${
      border ? "bb-l b--light-gray" : ""
    } ${className}`}
  >
    {children}
  </div>
);

const UserEditing = ({ user, onFinish, projectId, disabled }) => {
  const [email, setEmail] = useState(user?.email || "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [mutate] = useMutation(UPDATE_MUTATION, {
    onCompleted: ({ addUserToProjectMutation: { errorMessage } }) => {
      if (errorMessage) {
        setError(errorMessage);
      } else {
        onFinish();
      }
    },
  });

  const [deleteMutate] = useMutation(DELETE_MUTATION, {
    onCompleted: ({ deleteUserFromProjectMutation: { errorMessage } }) => {
      if (errorMessage) {
        setError(errorMessage);
      } else {
        onFinish();
      }
    },
  });

  const save = () => {
    if (Valid.Email(email)) {
      mutate({
        variables: {
          clientMutationId: new Date().getTime().toString(36),
          id: projectId,
          userId: user.databaseId,
          email,
          password,
        },
      });
    } else {
      setError("The address you entered is not an email address.");
    }
  };

  const del = () => {
    deleteMutate({
      variables: {
        clientMutationId: new Date().getTime().toString(36),
        userId: user.databaseId,
        id: projectId,
      },
    });
  };

  return (
    <Row
      left={
        <>
          <Label>Email</Label>
          <Input
            id="email"
            type="email"
            value={email}
            onEnter={save}
            onChange={(email) => setEmail(email)}
          />
          {error && <div className="fw7 f7 red mt2">{error}</div>}
        </>
      }
      middle={
        <>
          <Label>Password</Label>
          <div className="mr2-l">
            <Input
              id="password"
              value={password}
              onEnter={save}
              onChange={(password) => setPassword(password)}
            />
          </div>
          <div className="f7 ml-auto-l">
            <Button disabled={disabled} onClick={save}>
              Save
            </Button>
          </div>
        </>
      }
      right={
        <div className="f7 ml-auto-l db tc">
          <Button disabled={disabled} color="dark-red" onClick={del}>
            Delete
          </Button>
        </div>
      }
    />
  );
};

export const Row = ({ border = true, className = "", left, middle, right }) => (
  <div className={`dt-row-l bb b--light-gray ${className}`}>
    <Cell border={border}>{left}</Cell>
    <Cell border={border} className="w-50-l">
      <div className="flex-l items-center-l">{middle}</div>
    </Cell>
    <Cell border={border} className="tr-l">
      {right}
    </Cell>
  </div>
);

export const UserAdd = ({ onFinish, projectId }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [mutate] = useMutation(UPDATE_MUTATION, {
    onCompleted: ({ addUserToProjectMutation: { errorMessage } }) => {
      if (errorMessage) {
        setError(errorMessage);
      } else {
        onFinish();
      }
    },
  });

  const save = () => {
    if (Valid.Email(email)) {
      mutate({
        variables: {
          email,
          password,
          clientMutationId: new Date().getTime().toString(36),
          id: projectId,
        },
      });
    } else {
      setError("The address you entered is not an email address.");
    }
  };

  return (
    <Row
      left={
        <>
          <Label>Email</Label>
          <Input
            type="email"
            value={email}
            onEnter={save}
            onChange={(email) => setEmail(email)}
          />
          {error && <div className="fw7 f7 red mt2">{error}</div>}
        </>
      }
      middle={
        <>
          <div className="nb3-l">
            <Label>Password</Label>
            <Input
              value={password}
              onEnter={save}
              onChange={(password) => setPassword(password)}
            />
          </div>
        </>
      }
      right={
        <div className="f7 ml-auto-l">
          <Button onClick={save}>Add</Button>
        </div>
      }
    />
  );
};

export const UserRows = ({ user, ...props }) => {
  const [editing, setEditing] = useState(false);

  let editingCallback = () => true;

  // The purpose of this is so that when there are 0 users,
  // the unmounted comonent won't try and change state.
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    editingCallback = (bool) => setEditing(bool);

    return () => (editingCallback = () => true);
  }, [setEditing, editingCallback]);

  if (editing) {
    return (
      <UserEditing
        user={user}
        onFinish={() => editingCallback(false)}
        {...props}
      />
    );
  }

  return (
    <Row
      left={
        <>
          <Label>Email</Label>
          <span>{user.email}</span>
        </>
      }
      middle={
        <>
          <Label>Password</Label>
          <span className="db mr2">******</span>
          <div className="f7 ml-auto-l">
            <Button
              disabled={props.disabled}
              onClick={() => editingCallback(true)}
            >
              Change
            </Button>
          </div>
        </>
      }
      right={
        <>
          <Label>Last Login</Label>
          <span>{moment.utc(user.lastSeen).fromNow()}</span>
        </>
      }
    />
  );
};

export default UserRows;
