import React from "react";

import FileRows from "../dashboard/FileRows";

export const Documents = () => (
  <div className="documents">
    <h2 className="mt0">Documents</h2>
    <p>This is the list of documents.</p>

    <FileRows />
  </div>
);

export default Documents;
