import React, { useRef, useEffect } from "react";

import { isMobile } from "../utils/Browser";
import { resizeHandler } from "react-wp-gql";

export const ShortCards = ({
  title,
  featImage,
  animate = "",
  delay = "1s",
  className = "",
  onClick,
  ...props
}) => {
  const inner = useRef();
  const divTitle = useRef();

  useEffect(() => {
    const r = () => {
      if (inner?.current && divTitle?.current) {
        if (!isMobile()) {
          inner.current.style.bottom =
            "-" + divTitle.current.clientHeight + "px";
        } else {
          inner.current.style.removeProperty("bottom");
        }
      }
    };

    resizeHandler.add(r);

    r();

    return () => resizeHandler.remove(r);
  }, [inner, divTitle]);

  return (
    <div
      className={`${className} short-cards w-100 w-20-l overflow-hidden`}
      {...props}
    >
      <div
        className="animate__animated animate__bounceIn relative z-1 w-100 overflow-hidden"
        style={{
          paddingBottom: "100%",
          animationDelay: isMobile() ? "0" : delay,
        }}
      >
        <div
          ref={inner}
          className="inner absolute bottom-0 w-100 z-1 pointer"
          onClick={onClick}
        >
          {featImage && (
            <div
              className="bg-center cover"
              style={{
                paddingBottom: "100%",
                backgroundImage: `url("${featImage}")`,
              }}
            />
          )}
          <div ref={divTitle} className="lh-title f5 fw7 pv3 ph3 bg-white">
            {title}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortCards;
