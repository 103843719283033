import React from "react";
import { gql, useQuery } from "@apollo/client";
import { ReactComponent as SiteTours } from "../images/site-tours.svg";

const HERO_QUERY = gql`
  query HeroQuery {
    frontPage {
      id
      hero {
        background {
          sourceUrl(size: LARGE)
        }
        lowerTextBottom
        lowerTextTop
      }
    }
  }
`;

const OnQueryFinished = ({ error, data, className = "", ...props }) => {
  return (
    <div
      className={`hero vh-100 bg-navy flex items-center tc white bg-center cover relative z-1 ${className}`}
      {...props}
      style={{
        backgroundImage: data?.frontPage?.hero?.background?.sourceUrl
          ? `url("${data.frontPage.hero.background.sourceUrl}")`
          : null,
      }}
    >
      <div
        className="b--white-20 ba bw3 center flex justify-center flex-column relative z-2 pa2 pa4-l w-90"
        style={{ height: "590px", maxWidth: "590px" }}
      >
        <h1 className="flex ma0 mb4 items-center center">
          <span className="b--white-20 bb bw2 w3 db" />
          <span className="db mh2 fw3 f3">YATU360</span>
          <span className="b--white-20 bb bw2 w3 db" />
        </h1>
        <SiteTours width={502} className="center db" />
        <div className="ttu tracked bg-black pv2 ph4 center mt4">
          <div>{data?.frontPage?.hero?.lowerTextTop}</div>
          <div>{data?.frontPage?.hero?.lowerTextBottom}</div>
        </div>
      </div>

      <div className="absolute bottom-2 z-2 w-100">
        <div className="animate__animated animate__slow animate__heartBeat animate__repeat-2 w3 h3 center">
          <div
            onClick={() => {
              const el = document.getElementById("contact");

              if (el) {
                const bb = el.getBoundingClientRect();
                window.scrollTo({
                  top: bb.top - 100,
                  left: 0,
                  behavior: "smooth",
                });
              }
            }}
            className="bg-orange w3 h3 flex items-center br-100 pointer"
          >
            <span className="w-100 db f7">╲╱</span>
          </div>
        </div>
      </div>

      <div className="absolute absolute--fill bg-near-black bg-animate hover-bg-black o-50 z-1" />
    </div>
  );
};

const DefaultQuery = ({ children, ...props }) => {
  const { error, data } = useQuery(HERO_QUERY);

  return children({ data, error, ...props });
};

export const Hero = ({ query, ...props }) => {
  const LocalQuery = query || DefaultQuery;

  return (
    <LocalQuery {...props}>
      {(passedProps) => <OnQueryFinished {...passedProps} />}
    </LocalQuery>
  );
};

export default Hero;
