import React from "react";

import PlayBlock from "./PlayBlock";

export const Accountable = ({ className = "", ...props }) => (
  <PlayBlock
    {...props}
    title="How Your Job Site Accountable with YATU360"
    className={`accountable ${className}`}
    video="https://wordpress.yatu360.com/panorama/?v=fishermanshospitalpart3276020056"
  >
    <div className="f3 light-silver">
      Job Sites Digitally Captured. Effortlessly.
      <br />
      Track Job Site Work
    </div>
  </PlayBlock>
);

export default Accountable;
