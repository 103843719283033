import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { isMobile } from "../utils/Browser";
import { LoadingError, Permissions } from "react-wp-gql";
import Upload from "./Upload";
import Button from "../components/Button";

const NEXT = 1;
const PREV = -1;

const Objects = ({ Single, items, className = "", ...props }) => (
  <div className={`flex flex-wrap nl2 nr2 ${className}`}>
    {items &&
      items.map((item) => {
        if (item.node) {
          return <Single key={item.node.id} item={item.node} {...props} />;
        }

        return null;
      })}
  </div>
);

const Pagination = ({
  setPage = () => true,
  pageInfo,
  setPageInfo,
  setDirection,
}) => (
  <div className="cf">
    {pageInfo?.hasPreviousPage && (
      <Button
        className="fl"
        onClick={() => {
          setPage((p) => p - 1);
          setPageInfo(pageInfo);
          setDirection(PREV);
        }}
      >
        Previous
      </Button>
    )}

    {pageInfo?.hasNextPage && (
      <Button
        className="fr"
        onClick={() => {
          setPage((p) => p + 1);
          setPageInfo(pageInfo);
          setDirection(NEXT);
        }}
      >
        Next
      </Button>
    )}
  </div>
);

const DocUpload = ({ className, refetch, projectId, authorId }) => (
  <Permissions cap="edit_projects" authorId={2}>
    <Upload
      onFailure={(file) => {
        console.error(file);
      }}
      className={className}
      multiple={true}
      onComplete={() => refetch()}
      post={{ projectId, uploadType: "files" }}
    />
  </Permissions>
);

const RowForProject = ({
  projectId,
  authorId,
  className = "",
  variables,
  getDataItem = (data) => data?.files,
  Upload: PassedUpload = DocUpload,
  Single,
  query,
  ...props
}) => {
  const [version, setVersion] = useState(1);
  const [page, setPage] = useState(1);
  const size = isMobile() ? "MOBILE" : "DESKTOP";

  const { error, data } = useQuery(query(page, version), {
    variables: { ...variables, projectId, size },
  });

  if (error) return <LoadingError error={error.message} />;

  const item = getDataItem(data);

  if (!data) return null;

  return (
    <div>
      <Objects
        items={item?.edges?.length > 0}
        className={className}
        Single={Single}
      />

      <PassedUpload
        className="mt3"
        projectId={projectId}
        authorId={authorId}
        refetch={() => setVersion((v) => v + 1)}
      />

      <Pagination setPage={setPage} pageInfo={item?.pageInfo} {...props} />
    </div>
  );
};

const RowAll = ({
  className = "",
  getDataItem = (data) => data?.files,
  variables,
  Upload: PassedUpload = DocUpload,
  Single,
  query,
  ...props
}) => {
  const [version, setVersion] = useState(1);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(
    window.localStorage.getItem("perPage")
      ? window.localStorage.getItem("perPage")
      : 16
  );
  const size = isMobile() ? "MOBILE" : "DESKTOP";

  if (variables.first) {
    if ("all" === perPage) {
      variables.first = 100;
    } else {
      variables.first = parseInt(perPage);
    }
  }

  if (variables.last) {
    if ("all" === perPage) {
      variables.last = 100;
    } else {
      variables.last = parseInt(perPage);
    }
  }

  const { error, data } = useQuery(query(page, perPage, version), {
    variables: { ...variables, size },
  });

  if (error) return <LoadingError error={error.message} />;

  const item = getDataItem(data);

  return (
    <div>
      <PassedUpload refetch={() => setVersion((v) => v + 1)} />

      {item?.edges?.length > 0 && (
        <div className="mv2 cf">
          <div className="fr">
            <label>Per Page</label>{" "}
            <select
              defaultValue={perPage}
              onChange={(e) => {
                setPerPage(e.target.value);
                window.localStorage.setItem("perPage", e.target.value);
              }}
            >
              <option value="8">8</option>
              <option value="16">16</option>
              <option value="24">24</option>
              <option value="all">all</option>
            </select>
          </div>
        </div>
      )}

      <Objects
        items={item?.edges?.length > 0 && item.edges}
        className={className}
        Single={Single}
      />

      <Pagination setPage={setPage} pageInfo={item?.pageInfo} {...props} />
    </div>
  );
};

export const DocRows = ({ pageSize = 16, queries, ...props }) => {
  const [PRO_QUERY, ALL_QUERY] = queries;
  const [direction, setDirection] = useState(0);
  const [pageInfo, setPageInfo] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: "",
    endCursor: "",
  });

  const variables = {
    first: pageSize,
  };

  if (NEXT === direction) {
    variables.after = pageInfo.endCursor;
    variables.first = pageSize;
    variables.before = null;
    variables.last = null;
  }

  if (PREV === direction) {
    variables.after = null;
    variables.first = null;
    variables.before = pageInfo.startCursor;
    variables.last = pageSize;
  }

  if (props.projectId) {
    return (
      <RowForProject
        setPageInfo={setPageInfo}
        setDirection={setDirection}
        variables={variables}
        query={PRO_QUERY}
        {...props}
      />
    );
  }

  return (
    <RowAll
      setPageInfo={setPageInfo}
      setDirection={setDirection}
      variables={variables}
      query={ALL_QUERY}
      {...props}
    />
  );
};

export default DocRows;
