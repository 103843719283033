import React from "react";
import { Seo, PageWidth, Title, NotFound, Permissions } from "react-wp-gql";
import {
  NavLink,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import ProjectAdd from "./ProjectAdd";
import ProjectView from "./ProjectView";
import Documents from "./Documents";
import Profile from "./Profile";
import Projects from "./Projects";
import Videos from "./Videos";

const RedirectToLogin = () => {
  const location = useLocation();
  return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
};

export const Dashboard = () => {
  return (
    <Permissions cap="read_documents" fallback={RedirectToLogin} wait>
      <div className="dashboard absolute-l absolute--fill-l flex-l items-stretch-l flex-column-l">
        <Seo>
          <title>Dashboard | YATU360</title>
        </Seo>

        <Title className="shadow-4-l relative z-2">Dashboard</Title>

        <div
          className="flex flex-column db-l relative z-1"
          style={{ flexGrow: 1 }}
        >
          <div className="dashboard-navigation w-20-l mt4 mt0-l order-2 absolute-l top-0-l left-0-l bottom-0-l overflow-y-auto-l">
            <nav className="tr-l br-l b--moon-gray overflow-hidden bg-light-gray h-100-l">
              <NavLink
                exact
                activeClassName="bg-navy white"
                className="db pa3 hover-bg-orange hover-white bb b--moon-gray"
                to="/dashboard/profile"
              >
                Profile
              </NavLink>
              <Permissions cap="publish_projects">
                <NavLink
                  exact
                  activeClassName="bg-navy white"
                  className="db pa3 hover-bg-orange hover-white bb b--moon-gray"
                  to="/dashboard/projects/add"
                >
                  Add Project
                </NavLink>
              </Permissions>
              <NavLink
                exact
                activeClassName="bg-navy white"
                className="db pa3 hover-bg-orange hover-white bb b--moon-gray"
                to="/dashboard/projects"
              >
                Projects
              </NavLink>
              <NavLink
                exact
                activeClassName="bg-navy white"
                className="db pa3 hover-bg-orange hover-white bb b--moon-gray"
                to="/dashboard/videos"
              >
                Video Library
              </NavLink>
              <NavLink
                exact
                activeClassName="bg-navy white"
                className="db pa3 hover-bg-orange hover-white bb b--moon-gray"
                to="/dashboard/documents"
              >
                Document Library
              </NavLink>
              <NavLink
                exact
                activeClassName="bg-navy white"
                className="db pa3 hover-bg-orange hover-white bb b--moon-gray"
                to="/logout"
              >
                Logout
              </NavLink>
            </nav>
          </div>

          <div className="dashboard-main pa3 pa4-l order-1 absolute-l left-20-l top-0-l bottom-0-l right-0-l overflow-y-auto-l">
            <PageWidth>
              <Switch>
                <Route
                  exact
                  path="/dashboard/documents"
                  component={Documents}
                />
                <Route exact path="/dashboard/videos" component={Videos} />
                <Route exact path="/dashboard/projects" component={Projects} />
                <Route exact path="/dashboard/profile" component={Profile} />
                <Route exact path="/dashboard" component={Profile} />

                <Permissions
                  cap="publish_projects"
                  exact
                  path="/dashboard/projects/add"
                  component={ProjectAdd}
                  fallback={NotFound}
                  wait
                >
                  <ProjectAdd />
                </Permissions>

                <Route
                  exact
                  path="/dashboard/projects/:name"
                  component={ProjectView}
                />

                <Route path="*" component={NotFound} />
              </Switch>
            </PageWidth>
          </div>
        </div>
      </div>
    </Permissions>
  );
};

export default Dashboard;
