import React from "react";

import { BlocksTwo } from "../components/Blocks";
import { isWebpSupported } from "../utils/Browser";

export const Introduction = ({ className = "", ...props }) => (
  <div className={`introduction ${className}`} {...props}>
    <BlocksTwo
      flexClassName="items-stretch-l"
      left={
        <div>
          <div>Job Site Project Management</div>
          <h1 className="lh-title f3">
            Review 360&deg; virtual tours of your properties
          </h1>
          <p>
            From before and after photos, video and walk through digital
            footage, YATU360 is the best way to add efficiency and cost savings
            to your job site. Time and date stamped, full documentation is
            critical in today’s demanding construction environment.
          </p>
        </div>
      }
      right={
        <div className="flex-l items-stretch-l h5 h-100-l">
          <div
            className="w-40-l bg-white mr3 cover bg-center"
            style={{
              backgroundImage: isWebpSupported()
                ? `url("${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/curtains.webp")`
                : `url("${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/curtains.jpg")`,
            }}
          />
          <div className="w-60-l bg-orange white pa3">
            <div className="f4">YATU360 Property Type Examples:</div>
            <ul className="list pl0 f6">
              <li>Commercial &amp; Residential</li>
              <li>Hospital &amp; Medical Facilities</li>
              <li>Schools &amp; Universities</li>
              <li>Warehouse &amp; Light Industrial</li>
              <li>Office Complex</li>
              <li>Retail</li>
            </ul>
          </div>
        </div>
      }
    />
  </div>
);

export default Introduction;
