import React from "react";
import Animate from "../components/Animate";
import { isMobile } from "../utils/Browser";

export const TallCards = ({
  number,
  title,
  copy,
  featImage,
  animate = "",
  duration = "1s",
  className = "",
  ...props
}) => (
  <div
    className={`${className} tall-cards w-100 w-25-l flex-l flex-column-l items-stretch-l mb3 mb0-l`}
    {...props}
  >
    <Animate
      animate={`slideInUp ${animate}`}
      className="relative z-1 bg-white pa4 w-90-l center h-100-l"
      style={{ animationDuration: isMobile() ? "0" : duration }}
    >
      <div className="relative z-2">
        {featImage && (
          <div
            className="bg-center cover nr4 nl4"
            style={{
              paddingBottom: "100%",
              backgroundImage: `url("${featImage}")`,
            }}
          />
        )}
        {number && <div className="f3 lh-title orange">{number}</div>}
        <div className="lh-title f5 fw7 mv3">{title}</div>
        <div className="lh-copy f6">{copy}</div>
      </div>

      <div
        className="hover-bg bg-dark-gray bt bw1 b--orange absolute z-1 w-100 left-0 bottom-0"
        style={{ top: "calc( 100% - 1px )" }}
      />
    </Animate>
  </div>
);

export default TallCards;
