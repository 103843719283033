import React from "react";

import PlayBlock, { blockWidth } from "./PlayBlock";
import { PageWidth } from "react-wp-gql";
import TallCards from "./TallCards";

export const Situation = ({ className = "", ...props }) => (
  <PlayBlock
    {...props}
    title="A camera for every situation"
    className={`situation overflow-hidden ${className}`}
    video="https://wordpress.yatu360.com/panorama/?v=612industrialave330095049"
  >
    <div className="f3 light-silver center" style={{ maxWidth: blockWidth }}>
      Cameras on job sites do come in handy but video in a 360&deg; environment
      is even better! Stop the video, look up, down and all around to see what
      is. YATU360 edited videos are even available in VR (virtual reality)
      formats view-able in standard VR headsets such as Oculus.
    </div>

    <PageWidth className="mt4">
      <div className="flex-l items-stretch-l dark-gray">
        <TallCards
          number="01"
          duration="400ms"
          title="Time Savings"
          copy="Insurance claim adjusters can save time and money using YATU360 & use in reporting for documentation."
        />
        <TallCards
          number="02"
          duration="800ms"
          title="Effective"
          copy="Bidding process made simple & effective. Allows multiple vendors, subs and material experts to view job site online to meet deadlines & budgets."
        />
        <TallCards
          number="03"
          duration="1200ms"
          title="Disaster Relief"
          copy="Accident report writers, storm damage claim adjusters and even FEMA all use YATU360 . Unfortunately, mother nature comes calling sometimes and when she does, YATU360 is there to help you and your teams navigate through it all."
        />
        <TallCards
          number="04"
          duration="1600ms"
          title="Archive"
          copy="Archive, store and retrieve your data when needed. Some trades require record keeping for up to 40 years for certain types of construction documentation and YATU360 is there to help when you need it."
        />
      </div>
    </PageWidth>
  </PlayBlock>
);

export default Situation;
