import React from "react";
import { Link } from "react-router-dom";

import Image from "../components/Image";

export const Footer = () => (
  <footer id="footer" className="footer">
    <div className="copyright bg-near-black gray f7 tc pv4 flex-l items-center-l content-stretch-l w-100">
      <div className="mb3 mb0-l w-100">
        Copyright &copy; {new Date().getFullYear()} &bull; YATU360. All Rights
        Reserved
      </div>
      <div className="w-100">
        <Link to="/">
          <Image
            src={`${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/logo-orange.png`}
            webp={`${process.env.REACT_APP_DOMAIN}${process.env.PUBLIC_URL}/images/logo-orange.webp`}
            height="28"
          />
        </Link>
      </div>
      <div className="w-100" />
    </div>
  </footer>
);

export default Footer;
