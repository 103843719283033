import React, { Fragment, useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import { blockWidth } from "./PlayBlock";
import { isMobile } from "../utils/Browser";
import { updateLightBox } from "../components/LightBox";
import { resizeHandler, PostContent } from "react-wp-gql";
import ShortCards from "./ShortCards";

const QUERY = gql`
  query JobsQuery($amount: Int!) {
    portfoliosWithFeatImage(first: $amount) {
      nodes {
        id
        title
        content
        featuredImage {
          node {
            id
            sourceUrl(size: MEDIUM)
          }
        }
        portfolioCategories {
          nodes {
            id
            name
          }
        }
      }
    }
  }
`;

export const RecentJobs = ({ className = "", ...props }) => {
  const [visible, setVisible] = useState(null);
  const { error, data, refetch } = useQuery(QUERY, {
    variables: { amount: isMobile() ? 4 : 20 },
  });
  let ani = 400;

  const portfolios = data?.portfoliosWithFeatImage;

  useEffect(() => {
    let state = isMobile();
    const r = () => {
      refetch({ amount: isMobile() ? 4 : 20 });
    };

    resizeHandler.add(r, () => {
      if (state !== isMobile()) {
        state = isMobile();
        return true;
      }

      return false;
    });

    return () => resizeHandler.remove(r);
  }, [refetch]);

  const isVisible = (cats) => {
    if (cats?.length > 0) {
      let value;
      cats.forEach((c) => (value = c.id));
      return value === visible || null === visible;
    }

    return null === visible;
  };

  let categories = [];
  if (portfolios?.nodes?.length > 0) {
    portfolios.nodes.forEach((node) => {
      if (node?.portfolioCategories?.nodes?.length > 0) {
        node.portfolioCategories.nodes.forEach((cat) => categories.push(cat));
      }
    });
  }
  categories = [...new Set(categories)];

  return (
    <div className={`recent-job ${className}`} {...props}>
      <div className="center" style={{ maxWidth: blockWidth }}>
        <div className="lh-title f1 black ttu mb4">Recent Job Sites</div>
        <div className="mv4">
          Mapping features are built into the YATU360 back-end system to be able
          to view projects by standardized Google Maps and Google Maps API in
          conjunction with YATU360 degree virtual tours.
        </div>

        {error && <div>{error.message}</div>}
      </div>

      {categories.length > 0 && (
        <div className="mb4 flex-l items-stretch-l content-stretch-l tc">
          <div
            className="w-100 pointer dim mb3 mb0-l"
            onClick={() => setVisible(null)}
          >
            All
          </div>
          {categories.map((cat) => (
            <div
              className="w-100 pointer dim mb3 mb0-l"
              key={cat.id}
              onClick={() => setVisible(cat.id)}
            >
              {cat.name}
            </div>
          ))}
        </div>
      )}

      <div className="flex items-stretch flex-wrap dark-gray">
        {portfolios?.nodes?.length > 0 &&
          portfolios.nodes.map((portfolio) => (
            <Fragment key={portfolio.id}>
              {portfolio.featuredImage?.node?.sourceUrl &&
                isVisible(portfolio.portfolioCategories.nodes) && (
                  <ShortCards
                    featImage={portfolio.featuredImage.node.sourceUrl}
                    delay={`${ani}ms`}
                    title={portfolio.title}
                    onClick={() => {
                      updateLightBox(
                        <PostContent
                          className="aspect-ratio aspect-ratio--16x9"
                          content={portfolio.content}
                        />,
                        false
                      );
                    }}
                  >
                    {(ani = ani + 200)}
                  </ShortCards>
                )}
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export default RecentJobs;
