import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { Permissions } from "react-wp-gql";
import DocRows from "./DocRows";

const QUERY = (page, version = 1) => {
  return gql`
  query GetFilesProject($projectId: ID!) {
    files(first: 100, where: { projectId: $projectId }) @connection(key: "filesProjectPage${page}:v${version}", filter:["where"]) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          authorDatabaseId
          title
          uri
        }
      }
    }
  }
`;
};

const ALL_QUERY = (page, perPage, version = 1) => {
  return gql`
  query GetFilesAll($first: Int, $last: Int, $after: String, $before: String) {
    files(first: $first, last: $last, after: $after, before: $before) @connection(key: "filesAllPage${page}:${perPage}:v${version}") {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          authorDatabaseId
          title
          uri
        }
      }
    }
  }
`;
};

const DEL_MUTATION = gql`
  mutation deleteFile($clientMutationId: String!, $id: ID!) {
    deleteFileMutation(
      input: { clientMutationId: $clientMutationId, id: $id }
    ) {
      errorMessage
      debug
      deletedId
      file {
        id
      }
    }
  }
`;

const File = ({ item: file }) => {
  const [error, setError] = useState("");
  const [mutate] = useMutation(DEL_MUTATION, {
    onCompleted: ({ deleteFileMutation: { errorMessage, debug } }) => {
      if (debug) {
        alert(debug);
      }

      if (errorMessage) {
        setError(errorMessage);
      }
    },
    update: (cache, { data: deletedId }) => {
      if (deletedId) {
        setTimeout(() => {
          cache.evict(deletedId);
          cache.gc();
        }, 400);
      }
    },
  });

  const delete_file = (id) => {
    mutate({
      variables: { clientMutationId: new Date().getTime().toString(36), id },
    });
  };

  return (
    <div className="w-100 pa2 striped--near-white ml2 mr2">
      <div className="f7 flex items-center lh-solid">
        <div>
          <a href={file.uri} target="_new">
            {file.title}
          </a>
        </div>
        <Permissions cap="delete_documents" authorId={file.authorDatabaseId}>
          <div
            className="pointer ml3 f3 ml-auto"
            onClick={() =>
              window.confirm(`Do you wish to delete ${file.title}?`) &&
              delete_file(file.id)
            }
          >
            🗑
          </div>
        </Permissions>
      </div>
      {error && <div className="red f7 fw7">{error}</div>}
    </div>
  );
};

export const FileRows = (props) => {
  return <DocRows Single={File} queries={[QUERY, ALL_QUERY]} {...props} />;
};

export default FileRows;
