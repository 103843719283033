import React from "react";

import VideoRows from "../dashboard/VideoRows";

export const Videos = () => (
  <div className="videos">
    <h2 className="mt0">Videos</h2>
    <p>This is the list of videos.</p>

    <VideoRows />
  </div>
);

export default Videos;
