import React, { useState } from "react";

import { BlocksTwoFull } from "../components/Blocks";

const ContentBlock = ({ number, title, children, active = false, onClick }) => (
  <div className="mb3 overflow-hidden">
    <div
      onClick={onClick}
      className="bg-white pa3 fw7 f4 pointer flex items-center relative z-2"
    >
      <span className="orange db mr3">{number}</span>
      <span>{title}</span>
      <span className={`ml-auto caret f7 ${active ? "dn" : "db"}`}>╲╱</span>
      <span className={`ml-auto caret f7 ${active ? "db" : "dn"}`}>╱╲</span>
    </div>

    <div
      className={`pl3 pr3 pt3 relative z-1 ${
        active ? "animate__animated animate__slideInDown" : "dn"
      }`}
    >
      {children}
    </div>
  </div>
);

export const WhyUse = (props) => {
  const [active, setActive] = useState(1);

  return (
    <BlocksTwoFull
      {...props}
      flexClassName="items-stretch-l"
      left={
        <div className="pa5-l pa3">
          <div className="fw3 f2 lh-title">Why Use YATU360?</div>
          <div className="mb4 fw7 f2 lh-title">
            Trusted and deployed by many
          </div>

          <div>
            <ContentBlock
              onClick={() => setActive(1)}
              active={active === 1}
              number="01"
              title="Overall Process Improvement"
            >
              From RFI deadlines to subcontractor selection of bids, YATU360
              makes the process quicker and easier. Subs can view job sites
              online and ask questions without traveling to the job site
              multiple times.
            </ContentBlock>

            <ContentBlock
              onClick={() => setActive(2)}
              active={active === 2}
              number="02"
              title="Just-in-time crew development"
            >
              Make sure the project is ready at the right time for the right
              people. No more deployment of expensive equipment or staff only to
              find the job site is not ready for them quite yet. Whether
              demolition or ready for inspection, let YATU360 save you time and
              money on every job!
            </ContentBlock>

            <ContentBlock
              onClick={() => setActive(3)}
              active={active === 3}
              number="03"
              title="Full job site documentation"
            >
              Time and date stamp, before and after photos and videos all cut
              down on arguments of "what was" vs. "what is" now and who is
              responsible. Insurance claims and Inspectors will appreciate this
              as well as General Contractors, Property Owners, Property Managers
              and Government Agencies.
            </ContentBlock>

            <ContentBlock
              onClick={() => setActive(4)}
              active={active === 4}
              number="04"
              title="Capture reality, in 360&deg;"
            >
              Walking through a job site can be quick but you have to take time
              to get there. Often, reporting takes place after and a return
              visit to review progress. YATU360 offers multiple capture points
              throughout the construction project for you and allows your team
              to review quickly and efficiently 360° views of what is happening
              on site without even being there.
            </ContentBlock>
          </div>
        </div>
      }
      right={
        <div
          className="bg-center cover absolute absolute--fill"
          style={{
            backgroundImage:
              "url(https://wordpress.yatu360.com/wp-content/uploads/2019/07/arch.jpg)",
          }}
        />
      }
    />
  );
};

export default WhyUse;
