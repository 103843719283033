import React from "react";
import { Upload as UploadSource } from "react-wp-form";

const Upload = (props) => {
  return (
    <UploadSource
      progressBarClassName="bg-green"
      BACKEND_URL={process.env.REACT_APP_DOMAIN}
      {...props}
    />
  );
};

export default Upload;
