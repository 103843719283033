import React from "react";

import { updateLightBox } from "../components/LightBox";

export const blockWidth = "485px";

export const PlayBlock = ({
  className = "",
  video = "",
  title = "",
  children,
  ...props
}) => (
  <div className={`play-block relative z-1 ${className}`} {...props}>
    <div className="white tc w-100 center lh-title relative z-2">
      <div className="center" style={{ maxWidth: blockWidth }}>
        <div
          className="db center b--white ba bw2 br-100 flex items-center f2 play-button pointer"
          onClick={() => {
            updateLightBox(
              <div className="aspect-ratio aspect-ratio--16x9">
                <iframe
                  title={title}
                  className="aspect-ratio--object"
                  src={video}
                  allowFullScreen
                />
              </div>,
              false
            );
          }}
        />
        <div className="f2 mv4">{title}</div>
      </div>
      {children}
    </div>

    <div className="bg-black o-50 absolute z-1 absolute--fill" />
  </div>
);

export default PlayBlock;
