import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { updateLightBox } from "../components/LightBox";
import { Permissions } from "react-wp-gql";
import Upload from "./Upload";
import DocRows from "./DocRows";

const QUERY = (page, version = 1) => {
  return gql`
  query GetVideosProject($projectId: ID!, $size: VideoIframeSizesEnum!) {
    videos(first: 100, where: { projectId: $projectId }) @connection(key: "videosProjectPage${page}:v${version}", filter:["where"]) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          authorDatabaseId
          title
          poster
          iframe(size: $size)
          processing
        }
      }
    }
  }
`;
};

const ALL_QUERY = (page, perPage, version = 1) => {
  return gql`
  query GetVideosAll(
    $size: VideoIframeSizesEnum!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    videos(
      first: $first,
      last: $last,
      after: $after,
      before: $before
    ) @connection(
      key: "videosAllPage${page}:${perPage}:v${version}",
      filter:["size"]
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          name
          authorDatabaseId
          title
          poster
          iframe(size: $size)
          processing
        }
      }
    }
  }
`;
};

const DEL_MUTATION = gql`
  mutation deleteVideo($clientMutationId: String!, $id: ID!) {
    deleteVideoMutation(
      input: { clientMutationId: $clientMutationId, id: $id }
    ) {
      errorMessage
      debug
      deletedId
      video {
        id
      }
    }
  }
`;

const Video = ({ item: video }) => {
  const [error, setError] = useState("");
  const [mutate] = useMutation(DEL_MUTATION, {
    onCompleted: ({ deleteVideoMutation: { errorMessage, debug } }) => {
      if (debug) {
        alert(debug);
      }

      if (errorMessage) {
        setError(errorMessage);
      }
    },
    update: (cache, { data: deletedId }) => {
      if (deletedId) {
        setTimeout(() => {
          cache.evict(deletedId);
          cache.gc();
        }, 400);
      }
    },
  });

  const delete_video = (id) => {
    mutate({
      variables: { clientMutationId: new Date().getTime().toString(36), id },
    });
  };

  return (
    <div className="w-100 w-50-m w-25-l pa2">
      <div className="aspect-ratio aspect-ratio--16x9">
        <div
          className="bg-center cover aspect-ratio--object pointer"
          style={{ backgroundImage: `url("${video.poster}")` }}
          onClick={() => {
            updateLightBox(
              <div className="aspect-ratio aspect-ratio--16x9">
                <iframe
                  title={video.title}
                  className="aspect-ratio--object"
                  src={video.iframe}
                  allowFullScreen
                />
              </div>,
              false
            );
          }}
        />
      </div>
      <div className="f7 mt3 flex items-center lh-solid">
        <div className="word-wrap">{video.title}</div>
        <Permissions cap="delete_documents" authorId={video.authorDatabaseId}>
          <div
            className="pointer ml3 f3 ml-auto"
            onClick={() =>
              window.confirm(`Do you wish to delete ${video.title}?`) &&
              delete_video(video.id)
            }
          >
            🗑
          </div>
        </Permissions>
      </div>
      {error && <div className="red f7 fw7">{error}</div>}
    </div>
  );
};

const VideoUpload = ({ className, refetch, projectId, authorId }) => {
  return (
    <Permissions cap="edit_projects" {...{ authorId }}>
      <Upload
        onFailure={(file) => {
          console.error(file);
        }}
        onComplete={() => refetch()}
        className={className}
        post={{ projectId }}
        accept="video/mpeg, video/webm, video/ogg, video/x-flv, video/mp4, application/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv"
      />
    </Permissions>
  );
};

export const VideoRows = (props) => {
  return (
    <DocRows
      Upload={VideoUpload}
      Single={Video}
      queries={[QUERY, ALL_QUERY]}
      getDataItem={(data) => data?.videos}
      {...props}
    />
  );
};

export default VideoRows;
