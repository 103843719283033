import React from "react";

import { blockWidth } from "./PlayBlock";
import { PageWidth } from "react-wp-gql";
import TallCards from "./TallCards";

export const Services = ({ className = "", ...props }) => (
  <div className={`services overflow-hidden ${className}`} {...props}>
    <div className="lh-title f1 black ttu">YATU360 Services</div>
    <div className="center lh-title f5 mt4" style={{ maxWidth: blockWidth }}>
      Video Capture, Video Editing and Video 360&deg; Tours of Construction Job
      Sites with Time/Date Stamp Documentation
    </div>

    <PageWidth className="mt4">
      <div className="flex-l items-stretch-l dark-gray">
        <TallCards
          featImage="https://wordpress.yatu360.com/wp-content/uploads/2019/07/612industrialave330095049.jpg"
          duration="400ms"
          title="Project Package Updates"
          copy="Do you want an updated progress 360&deg; tour every day or every few days? YATU360 provides progress update footage throughout the life cycle of the building project."
        />
        <TallCards
          featImage="https://wordpress.yatu360.com/wp-content/uploads/2019/07/naskingsbay6118endofday273422302.jpg"
          duration="800ms"
          title="Secure"
          copy="YATU360 understands that many projects are time sensitive and focused on security. That is why we secure and encrypt every video and place behind a login screen to share only with the appropriate parties."
        />
        <TallCards
          featImage="https://wordpress.yatu360.com/wp-content/uploads/2019/07/fleetlandingday2320028113.jpg"
          duration="1200ms"
          title="Inspections"
          copy="Inspections, inspectors and the rest of us. We know that some vendors and managers do not always see eye to eye. YATU360 takes the stress away by sharing and communicating online in this process to help limit the amount of conflict, confusion and back and forth."
        />
        <TallCards
          featImage="https://wordpress.yatu360.com/wp-content/uploads/2019/07/june5th2018nasjax4thflooreast274574461.jpg"
          duration="1600ms"
          title="Custom"
          copy="Video editing can be customized. Some need roof shots and tours, others walls and ceilings. Whatever the case may be, YATU360 has it covered and can edit and customize your data for certain aspects of the construction project."
        />
      </div>
    </PageWidth>
  </div>
);

export default Services;
