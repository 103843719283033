import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Loading, LoadingError } from "react-wp-gql";
import Button from "../components/Button";

const QUERY = gql`
  query ProjectsQuery(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    viewer {
      id
      projects(
        first: $first
        last: $last
        after: $after
        before: $before
        where: { status: PUBLISH, hasPassword: false }
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            title
            databaseId
            slug
            schedule {
              projectFieldset1Start
              projectFieldset1ScheduledEnd
            }
            featuredImage {
              node {
                sourceUrl(size: LARGE)
              }
            }
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation DeleteProjectByOwner($id: ID!, $clientMutationId: String!) {
    deleteProject(input: { clientMutationId: $clientMutationId, id: $id }) {
      deletedId
      project {
        id
      }
    }
  }
`;

const NEXT = 1;
const PREV = -1;
const postsPerPage = 10;

const ProjectList = () => {
  const [direction, setDirection] = useState(0);
  const [pageInfo, setPageInfo] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: "",
    endCursor: "",
  });

  let variables = {
    first: postsPerPage,
  };

  if (NEXT === direction) {
    variables.after = pageInfo.endCursor;
    variables.first = postsPerPage;
    variables.before = null;
    variables.last = null;
  }

  if (PREV === direction) {
    variables.after = null;
    variables.first = null;
    variables.before = pageInfo.startCursor;
    variables.last = postsPerPage;
  }

  const { loading, error, data } = useQuery(QUERY, { variables });

  if (loading) return <Loading />;
  if (error) return <LoadingError error={error.message} />;

  if (data?.viewer?.projects?.edges?.length > 0) {
    return (
      <div>
        <ul className="list pl0">
          {data.viewer.projects.edges.map((n) => (
            <ProjectRow key={n.node.id} node={n.node} />
          ))}
        </ul>

        {data.viewer.projects.pageInfo?.hasPreviousPage && (
          <Button
            className="fl"
            onClick={() => {
              setPageInfo(data.viewer.projects.pageInfo);
              setDirection(PREV);
              window.scrollTo(0, 0);
            }}
          >
            Previous
          </Button>
        )}

        {data.viewer.projects.pageInfo?.hasNextPage && (
          <Button
            className="fr"
            onClick={() => {
              setPageInfo(data.viewer.projects.pageInfo);
              setDirection(NEXT);
              window.scrollTo(0, 0);
            }}
          >
            Next
          </Button>
        )}
      </div>
    );
  }

  return (
    <div>
      <p>No projects found.</p>
    </div>
  );
};

const ProjectRow = ({ node }) => {
  const row = useRef();

  const onCompleted = () => {
    if (row?.current) {
      row.current.classList.add("animate__animated", "animate__bounceOutRight");
    }
  };

  const [runMutation, { loading }] = useMutation(MUTATION, { onCompleted });
  const del = () => {
    runMutation({
      variables: {
        id: node.id,
        clientMutationId: node.id + new Date().getTime().toString(36),
      },
      update: (cache, { data: deletedId }) => {
        setTimeout(() => {
          cache.evict(deletedId);
          cache.gc();
        }, 400);
      },
    });
  };

  return (
    <li
      ref={row}
      className="bg-near-white ba b--moon-gray gray f7 mb3 relative z-1 flex-l"
    >
      <div className="ma2 w-30-l relative z-1 black fw6">
        <div className="w3-l w-50">
          <div
            style={{
              backgroundImage: `url("${node.featuredImage?.node?.sourceUrl}")`,
            }}
            className="bg-center cover aspect-ratio--4x3 aspect-ratio mb2 grow"
          />
        </div>
        <div>{node.title}</div>
        <div>
          <Link
            to={`/dashboard/projects/${node.slug}`}
            className="db absolute absolute--fill z-2"
          />
        </div>
      </div>
      <div className="ma2">
        <span className="db mb1 moon-gray fw7">Start</span>
        {node.schedule?.projectFieldset1Start}
      </div>
      <div className="ma2">
        <span className="db mb1 moon-gray fw7">End</span>
        {node.schedule?.projectFieldset1ScheduledEnd}
      </div>
      <div className="self-center-l ml-auto-l ma2">
        <Button onClick={del}>
          <span>Delete</span>
          {loading && <Loading color="#FFF" className="f7 ml2 nt2 nb2 nr3" />}
        </Button>
      </div>
    </li>
  );
};

export const Projects = () => (
  <div className="projects">
    <h2 className="mt0">Projects</h2>
    <p>This is the list of your projects. Click on one to view the project.</p>
    <ProjectList />
  </div>
);

export default Projects;
