import React, { Component } from "react";

let updateLightBox;

export class LightBox extends Component {
  state = {
    content: null,
    chrome: true,
  };

  componentDidMount() {
    updateLightBox = (content = "div", chrome = true) => {
      this.setState({ content, chrome });
    };
  }

  render() {
    const { content, chrome } = this.state;

    const active = content !== null;

    return (
      <div
        id="lightbox-anchor"
        className={
          active
            ? "z-2 fixed absolute--fill flex items-center animate__animated animate__fadeIn"
            : "dn"
        }
      >
        <div className="bg-white center w-100 w-80-l relative z-2">
          <div
            className={`pa2 flex ${
              chrome
                ? "bg-near-white"
                : "absolute top-0 w-100 white text-shadow z-2"
            }`}
          >
            <div
              className="ml-auto pointer f3 lh-solid"
              onClick={() => this.setState({ content: null })}
            >
              &times;
            </div>
          </div>
          <div className={chrome ? "pa4 relative z-1" : "relative z-1"}>
            {content}
          </div>
        </div>

        <div
          className="absolute absolute--fill bg-black-50 z-1"
          onClick={() => this.setState({ content: null })}
        />
      </div>
    );
  }
}

export { updateLightBox };
export default LightBox;
