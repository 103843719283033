import React, { useState, useEffect, useCallback } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { FormGroup as Input } from "react-wp-form";
import Image from "../components/Image";
import Button from "../components/Button";
import Upload from "../dashboard/Upload";
import { useHistory } from "react-router-dom";

const MUTATION = gql`
  mutation CreateProjectByOwner(
    $clientMutationId: String!
    $startDate: String!
    $endDate: String
    $street1: String!
    $street2: String
    $city: String!
    $state: String!
    $zip: String!
    $thumbnail: Int
  ) {
    createProjectWithMetaMutation(
      input: {
        clientMutationId: $clientMutationId
        startDate: $startDate
        endDate: $endDate
        street1: $street1
        street2: $street2
        city: $city
        state: $state
        zip: $zip
        thumbnail: $thumbnail
      }
    ) {
      project {
        id
        title
        databaseId
        slug
        schedule {
          projectFieldset1Start
          projectFieldset1ScheduledEnd
        }
        featuredImage {
          node {
            sourceUrl(size: LARGE)
          }
        }
      }
    }
  }
`;

const GOT_IMAGE = gql`
  query GotImage($thumbnail: ID!) {
    mediaItem(id: $thumbnail, idType: DATABASE_ID) {
      id
      sourceUrl(size: THUMBNAIL)
    }
  }
`;

const UploadedImage = ({ thumbnail }) => {
  const { data } = useQuery(GOT_IMAGE, {
    variables: { thumbnail },
    skip: !thumbnail,
    fetchPolicy: "network-only",
  });

  const src = data?.mediaItem?.sourceUrl;

  if (!thumbnail || !data) {
    return null;
  }

  return <Image {...{ src }} />;
};

const AddForm = () => {
  const history = useHistory();
  const [form, setForm] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    street1: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    thumbnail: 0,
  });
  const [redirect, setRedirect] = useState(false);
  const [buttonActive, setButtonActive] = useState(true);
  const [errorMessage, setErrorMessage] = useState([]);
  const { thumbnail } = form;

  const validate = useCallback(() => {
    const _start = Date.parse(form.startDate);
    const _end = Date.parse(form.endDate);
    let valid = true;

    setErrorMessage([]);

    if (_start > _end) {
      setErrorMessage((existing) => [
        ...existing,
        "The scheduled end date must come after the start date.",
      ]);
      valid = false;
    }

    if ("" === form.street1) {
      setErrorMessage((existing) => [
        ...existing,
        "Street address is required.",
      ]);
      valid = false;
    }

    if ("" === form.zip) {
      setErrorMessage((existing) => [...existing, "Postal Code is required."]);
      valid = false;
    }

    if (valid) {
      setErrorMessage([]);
    }

    return valid;
  }, [form, setErrorMessage]);

  const onCompleted = ({ createProjectWithMetaMutation: { project } }) => {
    if (project?.slug) {
      setRedirect(`/dashboard/projects/${project.slug}`);
    }

    setForm({
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      street1: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      thumbnail: 0,
    });
  };

  const [runMutation, { loading }] = useMutation(MUTATION, { onCompleted });

  const submit = () => {
    // Validate.
    const valid = validate();
    if (!valid) {
      return;
    }

    runMutation({
      variables: {
        ...form,
        clientMutationId: "ADDPROJECT" + new Date().getTime().toString(36),
      },
    });
  };

  useEffect(() => {
    let timeout = null;
    if (redirect) {
      timeout = setTimeout(() => {
        history.push(redirect);
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [history, redirect, validate, errorMessage]);

  return (
    <div className="cf">
      {errorMessage && (
        <div className="red fw7 mv3 f4">
          {[...new Set(errorMessage)].map((e) => (
            <p key={e}>{e}</p>
          ))}
        </div>
      )}

      <div className="mb3">Thumbnail</div>
      <Upload
        accept="image/*"
        onStart={() => setButtonActive(false)}
        onFailure={() => setButtonActive(true)}
        onComplete={(file) => {
          if (file.response) {
            setForm((prev) => ({
              ...prev,
              thumbnail: parseInt(file.response),
            }));
          }

          setButtonActive(true);
        }}
      />

      <UploadedImage {...{ thumbnail }} />

      <Input
        id="project-start-date"
        label="Start Date"
        value={form.startDate}
        onChange={(startDate) => setForm({ ...form, startDate })}
        type="date"
        onEnter={submit}
        min={moment().format("YYYY-MM-DD")}
        pattern="\d{2}/\d{2}/\d{4}"
      />

      <Input
        id="project-scheduled-end-date"
        label="Scheduled End Date"
        value={form.endDate}
        onChange={(endDate) => setForm({ ...form, endDate })}
        type="date"
        onEnter={submit}
        min={form.startDate}
        pattern="\d{2}/\d{2}/\d{4}"
      />

      <h3>Location</h3>

      <Input
        id="project-street-1"
        label="Address Line 1"
        value={form.street1}
        onChange={(street1) => setForm({ ...form, street1 })}
        onEnter={submit}
      />

      <Input
        id="project-street-2"
        label="Address Line 2"
        value={form.street2}
        onChange={(street2) => setForm({ ...form, street2 })}
        onEnter={submit}
      />

      <div className="cf nl2-l nr2-l">
        <Input
          id="project-city"
          label="City"
          value={form.city}
          onChange={(city) => setForm({ ...form, city })}
          onEnter={submit}
          className="fl-l w-33-l ph2-l"
        />

        <Input
          id="project-state"
          label="State"
          value={form.state}
          onChange={(state) => setForm({ ...form, state })}
          onEnter={submit}
          className="fl-l w-33-l ph2-l"
        />

        <Input
          id="project-zip"
          label="Postal Code"
          value={form.zip}
          onChange={(zip) => setForm({ ...form, zip })}
          onEnter={submit}
          className="fl-l w-33-l ph2-l"
        />
      </div>

      <div className="fr mt3 flex items-center">
        <Button
          disabled={!buttonActive}
          form={true}
          onClick={submit}
          {...{ loading }}
        >
          <span>Update</span>
        </Button>
      </div>
    </div>
  );
};

export const ProjectAdd = () => (
  <div className="add-project">
    <h2 className="mt0">Add Project</h2>
    <p>This is where you can add a project.</p>
    <AddForm />
  </div>
);

export default ProjectAdd;
